import React, { FC } from "react";
import { Box, Text } from "theme-ui";
import styles from "./landing.style";
import PhotoSlider from "../photo-slider/photo-slider";
import LandingSliceBase from "components/landing-slice-base/landing-slice-base";

type PropsType = {
  content: any;
  gallery: any;
  slider: boolean;
  title?: string;
};
const AgroforestLanding: FC<PropsType> = ({
  content,
  gallery,
  slider,
  title,
}) => {
  return (
    <Box sx={styles.wrapper}>
      {slider && <PhotoSlider images={gallery} slice />}
      <Box sx={styles.content}>
        {content.length && <LandingSliceBase content={content} title={title} />}
      </Box>
    </Box>
  );
};
export default AgroforestLanding;
