import React, { FC } from "react";
import ContentContainer from "../components/container/content-container";
import SEO from "../components/seo";
import PrimaryLayout from "../components/layout/primary/primary";
import { graphql } from "gatsby";
import AgroforestLanding from "components/blog/agroforest-landing";

export const query = graphql`
  query {
    prismicArticleLandingPage(prismicId: { eq: "ZSle3REAACcAlCLs" }) {
      data {
        body {
          ... on PrismicArticleLandingPageDataBodyBanner {
            slice_type
            id
            primary {
              banner_image {
                url
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              link {
                uid
                url
                link_type
              }
            }
          }
          ... on PrismicArticleLandingPageDataBodyFeaturedArticles {
            slice_type
            id
            primary {
              link {
                uid
                url
                link_type
              }
            }
            items {
              title
              content {
                raw
              }
              image {
                fluid(maxWidth: 600) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              link {
                uid
                url
                link_type
              }
            }
          }
          ... on PrismicArticleLandingPageDataBodyText {
            slice_type
            id
            primary {
              text_block {
                raw
              }
            }
          }
          ... on PrismicArticleLandingPageDataBodyNewsSection {
            id
            items {
              image {
                fluid(maxWidth: 400) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              link_new_images {
                uid
                url
                link_type
              }
              title_news {
                raw
              }
              subtitle {
                raw
              }
            }
            slice_type
          }
        }
        metadescription {
          raw
        }
        photo_slider {
          gallery_image {
            fluid(maxWidth: 680) {
              ...GatsbyImgixFluid_noBase64
            }
          }
        }
        slider
      }
    }
  }
`;

interface AgroforestPageProps {
  data: any;
}

const AgroforestPage: FC<AgroforestPageProps> = ({ data }) => {
  const gallery = data.prismicArticleLandingPage.data.photo_slider;
  const content = data.prismicArticleLandingPage.data.body;
  const slider = data.prismicArticleLandingPage.data.slider;

  return (
    <PrimaryLayout>
      <SEO title="Agroforest" />
      <ContentContainer width="100%">
        {/* AgroforestLanding component */}
        <AgroforestLanding
          content={content}
          gallery={gallery}
          slider={slider}
          title="The Farm"
        />
        {/* ColorDivider component */}
      </ContentContainer>
    </PrimaryLayout>
  );
};

export default AgroforestPage;
