/** @jsx jsx */
import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { Box, jsx, Text } from "theme-ui";
import Carousel from "../carousel/carousel";
import styles from "./photo-slider.style";
import kebabCase from "lodash/kebabCase";
import cx from "classnames";

interface PhotoSliderProps {
  images?: any;
  link?: string;
  category?: string;
  featured?: any;
  slice?: boolean;
  hasPadding?: boolean;
  sameHeight?: boolean;
}

const PhotoSlider: React.FC<PhotoSliderProps> = ({
  images,
  link,
  category,
  featured,
  slice,
  hasPadding,
  sameHeight,
}) => {
  const categorySlug = kebabCase(category);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    ipad: {
      breakpoint: { max: 1024, min: 651 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };
  if (slice) {
    return (
      <Box
        sx={styles.wrapper}
        className={cx({
          "no-padding": hasPadding,
        })}
      >
        <Carousel
          visible={true}
          ssr={true}
          responsive={responsive}
          gutter="10px"
        >
          {images?.map((image: any, index: number) => {
            return (
              <Img
                key={`image-${index}`}
                fluid={image.gallery_image?.fluid}
                className={cx("carousel-image", {
                  "same-height": sameHeight,
                })}
              />
            );
          })}
        </Carousel>
      </Box>
    );
  }
  return (
    <Box sx={styles.wrapper}>
      <Carousel visible={true} ssr={true} responsive={responsive} gutter="10px">
        <Link to={`/${link}`} className="link">
          <Img fluid={featured?.fluid} className="carousel-image" />
        </Link>
        {images?.map((image: any, index: number) => {
          return (
            <Link to={`/${link}`} key={`image-${index}`} className="link">
              <Img
                fluid={image.gallery_image.fluid}
                className="carousel-image"
              />
            </Link>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default PhotoSlider;
