const styles = {
  wrapper: {
    display: "grid",
    width: "95%",
    margin: "0 auto !important",
    marginTop: "20px",
    padding: "0 80px 0 80px",
    "@media only screen and (min-width:768px)": {
      width: "100%",
    },
    "@media only screen and (max-width: 590px)": {
      width: "100%",
      padding: "0 20px 0 20px",
    },
    ".link": {
      width: "100%",
    },
   /*  ".carousel-button-group": {
      opacity: 0,
      visibility: "hidden" as "hidden",
      transition: "all 0.25s ease",
    }, */
    ".carousel-image": {
      width: "100%",
      "&.same-height": {
        height: "227px",
      },
    },
    /* "&:hover": {
      ".carousel-button-group": {
        opacity: 1,
        visibility: "visible" as "visible",
      },
    }, */
    "&.no-padding": {
      padding: "0",
    },
    /* "@media only screen and (max-width:1080px)": {
      ".carousel-button-group": {
        opacity: 0,
        visibility: "hidden" as "hidden",
      },
      "&:hover": {
        ".carousel-button-group": {
          opacity: 0,
          visibility: "hidden" as "hidden",
        },
      },
    }, */
    ".gatsby-image-wrapper": {
      height: "420px",
      "@media only screen and (max-width: 560px)": {
        height: "280px",
      },
    },
    img: {
      width: "100%",
      "object-fit": "cover",
    },
    ".react-multi-carousel-item": {},
  },
  catHeading: {
    display: "inline-block",
    color: "#D79A2B",
    fontSize: "18px",
    paddingTop: "2rem",
    paddingBottom: ".5rem",
    lineHeight: "1.3rem",
    "@media only screen and (min-width: 560px)": {
      fontSize: "23px",
      paddingBottom: "1.3rem",
    },
  },
};

export default styles;
